export class resulthelper {
  static setGraphDisplayOptions(options, model) {
    let question_row_count = options.traces[0]?.customdata.length

    options.layout.legend = {
      y: 5,
      orientation: "h",
      traceorder: 'normal'
    }
    options.layout.font.size = 18

    options.layout.plot_bgcolor = 'white'
    options.layout.paper_bgcolor = 'white'

    if (model.chartType === 'stackedbar') {
      options.traces.forEach(trace => {
        trace.textposition = 'inside'
        trace.insidetextanchor = 'middle'
      })

      //Add <br> to questions titles if they are too long
      let longest_question_length = 0
      options.layout.yaxis.ticktext = options.traces[0].customdata.map(data => {
        if (data.length > longest_question_length) {
          longest_question_length = data.length
        }
        let text_array = data.split(' ')
        let new_text = ''
        let line_length = 0
        text_array.forEach(word => {
          if (line_length + word.length > 65) {
            new_text += '<br>' + word + ' '
            line_length = word.length
          } else {
            new_text += word + ' '
            line_length += word.length
          }
        })
        return new_text
      })

      //Adjusting layout height in regard of data size
      if (question_row_count === 1) {
        options.layout.height = 110
        options.traces.forEach(trace => {
          trace.bargap = 1
          trace.width = 0.7
        })
      } else {
        options.layout.height = question_row_count < 7
          ? Math.max(question_row_count * longest_question_length / 2, 200)
          : Math.max(question_row_count * longest_question_length / 3, 220)

        options.traces.forEach(trace => {
          trace.bargap = 1
          trace.width = 0.8
        })
      }

      options.layout.margin = {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 0
      }
      options.layout.responsive = false

      options.layout.xaxis = {
        tickformat: ',.0%',
        //hide legend
        showticklabels: false,
      };

    } else if (model.chartType === 'pie' || model.chartType === 'doughnut') {
      options.layout.height = 300
    }
    options.layout.yaxis.autorange = "reversed"
  }
}
