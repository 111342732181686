<template>
  <div class="p-grid p-mb-4">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <div class="card">
    <Steps :model="items" :active-step="activeStep" :readonly="true"/>
  </div>

  <div class="p-mt-3 p-grid p-jc-center">
    <router-view
      v-slot="{ Component }"
      :formData="formObject"
      @prevPage="prevPage($event)"
      @nextPage="nextPage($event)"
      @complete="complete"
      class="p-col-10"
    >
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import Steps from 'primevue/steps'

export default {
  name: 'Requests',
  components: {
    Steps
  },
  data() {
    return {
      items: [
        {
          label: this.$i18n.t('form.step1.title'),
          click: this.activeStep = 0,
          to: 'teaching'
        },
        {
          label: this.$i18n.t('form.step2.title'),
          click: this.activeStep = 1,
          to: 'targetaudience'
        },
        {
          label: this.$i18n.t('form.step3.title'),
          to: 'surveyparams'
        },
        {
          label: this.$i18n.t('form.step4.title'),
          to: 'summary'
        }
      ],
      formObject: {},
      activeStep: 0
    }
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field]
      }
      this.$store.dispatch('request/save', this)
      this.activeStep = event.pageIndex + 1
      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.activeStep = event.pageIndex - 1
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    complete() {
      this.$store.dispatch('request/store', this)
    }
  }
}
</script>
