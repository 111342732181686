<template>
  <h1>Export des réponses</h1>
  <div id="surveyDashboardContainer"></div>
</template>

<script>
import "tabulator-tables/dist/css/tabulator.min.css";
import "survey-analytics/survey.analytics.tabulator.css";
import 'survey-analytics/survey.analytics.min.css';
import RequestService from "@/Services/request.service";
import ResultsService from "@/Services/results.service";
import {useRoute} from "vue-router";
import {Model} from "survey-core";
import * as XLSX from "xlsx";
import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";

window.XLSX = XLSX;

export default {

  name: 'request-results',
  mounted() {

    Promise.all([
      ResultsService.getResults(useRoute().params.id),
      RequestService.getRequest(useRoute().params.id)
    ])
      .then(data => {
        data[0].data.forEach(result => {
          if (result.json_definition) {
            this.survey_results.push(JSON.parse(result.json_definition))
          }
        })
        this.request = data[1].data.result
        this.survey = this.request.original_survey
        this.is_admin = sessionStorage.getItem('currentrole') === 'admin'

        if (this.is_admin) this.render()
      })
  },
  data() {
    return {
      request: null,
      survey: null,
      survey_results: [],
      loading: true,
    }
  },
  methods: {
    render() {
      const survey = new Model(this.survey);

      survey.locale = this.$i18n.locale

      //TODO fix special chars
      const visPanel = new SurveyAnalyticsTabulator.Tabulator(
        survey,
        this.survey_results,
        {haveCommercialLicense: true, downloadButtons: ["xlsx"]}
      );
      visPanel.render("surveyDashboardContainer");

    },
  }
}
</script>

