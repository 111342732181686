<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <div class="p-jc-center">
    <h3>Ressources</h3>
    <p class="p-mb-5"><a :href="links?.instruction_guide_url" target="_blank">{{ $t('misc.how_to') }}</a></p>

    <h3>Pour toute demande d'aide ou en cas de problème technique:</h3>
    <a class="p-button p-mb-5" href="mailto:cse-eval@unil.ch" target="_blank">Écrivez-nous</a>

    <h3 class="p-mt-5">Autres ressources</h3>
    <p><a :href="links?.objectives_url" target="_blank">La démarche d'EEE</a></p>
    <p><a :href="links?.info_url" target="_blank">Le site de l'EEE</a></p>

  </div>
</template>
<script>
export default {
  name: 'Help',
  computed: {
    links() {
      return this.$store.state.params.links
    }
  },
}
</script>
