<template>
  <div class="p-mb-2">
    <small v-show="validationErrors.speakers" class="p-error">{{ $t("form.step1.fields.speakers.error") }}</small>
  </div>

  <div id="speakers-container" :class="'p-fieldset p-mt-2' + (validationErrors.speakers ? ' invalid-content' : '')">
    <div class="card">
      <div class="p-col-2">
        <Button :label="$t('misc.add')" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2 p-mt-2 p-button-sm" @click="openNew"/>
      </div>

      <DataTable ref="dt" :value="speakersArray"
                 v-show="speakersArray?.length !== 0"
                 :filters="filters"
                 @value-change="emitSpeakers"
                 responsiveLayout="scroll">

        <Column field="name" header="Name" :sortable="true"></Column>

        <Column field="email" header="Email" :sortable="true"></Column>

        <Column field="role" header="Role" :sortable="true">
          <template #body="slotProps">
            <span>{{ slotProps.data.role }}</span>
          </template>
        </Column>

        <Column :exportable="false">
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="p-button-99rounded p-button-warning"
                    @click="confirmDeleteSpeaker(slotProps.data)"/>
          </template>
        </Column>

      </DataTable>
    </div>

    <Dialog v-model:visible="speakerDialog" :style="{width: '450px'}" :header="$t('form.step1.add_speaker')" :modal="true"
            class="p-fluid">

      <div class="p-field">
        <label for="name">{{ $t("form.step1.fields.speakers.form.name.title") }}</label>
        <InputText id="name" v-model.trim="speaker.name" required="true" autofocus
                   :class="{'p-invalid': submitted && !speaker.name}"/>
        <small class="p-error" v-if="submitted && !speaker.name">{{
            $t("form.step1.fields.speakers.form.name.error")
          }}</small>
      </div>

      <div class="p-field">
        <label for="email">{{ $t("form.step1.fields.speakers.form.email.title") }}</label>
        <InputText pattern="[^@\s]+@[^@\s]+\.[^@\s]+" @change="checkMail" id="email" v-model="speaker.email"
                   required="true" rows="3" cols="20" :class="{'p-invalid': ismaildirty && (!speaker.email || !ismailvalid)}"/>
        <small class="p-error" v-if="ismaildirty && (!speaker.email || !ismailvalid)">{{
            $t("form.step1.fields.speakers.form.email.error")
          }}</small>
      </div>

      <div class="p-field">
        <label for="role" class="p-mb-3">{{ $t("form.step1.fields.speakers.form.role.title") }}</label>
        <Dropdown id="role" v-model="speaker.role" :options="roles" optionLabel="label"
                  :placeholder='$t("form.step1.fields.speakers.form.role.error")'
                  :class="{'p-invalid': submitted && !speaker.role}">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span>{{ slotProps.value.label }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span>{{ slotProps.value }}</span>
            </div>
            <span v-else>
							{{ slotProps.placeholder }}
						</span>
          </template>
        </Dropdown>
        <small class="p-error" v-if="submitted && !speaker.role">{{ $t("form.step1.fields.speakers.form.role.error") }}</small>

      </div>

      <template #footer>
        <Button :label="$t('misc.cancel')" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button :disabled=!ismailvalid :label="$t('misc.save')" icon="pi pi-check" class="p-button-text" @click="saveSpeaker"/>
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteSpeakerDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="speaker">Are you sure you want to delete <b>{{ speaker.name }}</b>?</span>
      </div>
      <template #footer>
        <Button :label="$t('misc.no')" icon="pi pi-times" class="p-button-text" @click="deleteSpeakerDialog = false"/>
        <Button :label="$t('misc.yes')" icon="pi pi-check" class="p-button-text" @click="deleteSpeaker"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api'

export default {
  inheritAttrs: false,
  props: {
    validationErrors: Object,
    speakers: Array
  },
  data() {
    return {
      speakersArray: [],
      ismailvalid: true,
      ismaildirty: false,
      speakerDialog: false,
      deleteSpeakerDialog: false,
      speaker: {},
      selectedSpeakers: null,
      filters: {},
      submitted: false,
      //TODO get roles from DB
      roles: [
        {label: this.$t('form.step1.main_teacher'), value: 'RESP ENS'},
        {label: this.$t('form.step1.assistant'), value: 'ASSIST'},
      ]
    }
  },
  watch: {
    // whenever question changes, this function will run
    speakers(newValue, oldValue) {
      this.speakersArray = newValue
    }
  },
  created() {
    this.initFilters();
    this.speakersArray = this.speakers
  },
  methods: {
    checkMail() {
      this.ismaildirty = true
      this.ismailvalid = document.getElementById("email").reportValidity()
    },
    emitSpeakers(event) {
      this.$emit('value-change', event)
    },
    openNew() {
      this.speaker = {};
      this.submitted = false;
      this.speakerDialog = true;
    },
    hideDialog() {
      this.speakerDialog = false;
      this.submitted = false;
    },
    saveSpeaker() {
      this.checkMail()
      this.submitted = true;
      if (this.speaker.name?.trim() && this.ismailvalid && this.speaker.role) {
        this.speaker.role = this.speaker.role?.value;
        this.speakersArray.push(this.speaker);
        this.speakerDialog = false;
        this.speaker = {};
      }
    },
    confirmDeleteSpeaker(speaker) {
      this.speaker = speaker;
      this.deleteSpeakerDialog = true;
    },
    deleteSpeaker() {
      this.speakersArray = this.speakersArray.filter(speaker => speaker.email !== this.speaker.email);
      this.deleteSpeakerDialog = false;
      this.speaker = {};
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },
  emits: [
    "value-change",
  ]
}
</script>

<style scoped>
.invalid-content {
  border: 1px solid red;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>

