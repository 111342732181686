import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/surveys';

class SurveyService {

  getSurveys(params = '') {
    return axios.get(API_URL + '?' + params)
  }

  getSurveysByFacultyName(facultyName) {
    return axios.get(API_URL + '/faculty/' + facultyName)
  }

  createSurvey(data) {
    return axios.post(API_URL, data)
  }

  createSurveyModel(data) {
    data.is_visible = true
    return this.createSurvey(data)
  }

  deleteSurveyModel(id) {
    return axios.delete(API_URL + '/' + id)
  }
}

export default new SurveyService();
