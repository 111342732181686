<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>
  <h1>{{ is_admin ? $t('requests.title') : $t('my_requests.title') }}</h1>


  <TabView :activeIndex="active" @tabChange="switchToExportTab">
    <TabPanel>
      <template #header>
        <i class="pi pi-list p-mr-2"></i>
        <span>Demandes d'évaluation</span>
      </template>

      <div class="card">
        <DataTable ref="dt" sortMode="multiple" :multi-sort-meta="multi_sort_meta" :value="requestList"
                   :paginator="true" class="p-datatable-customers"
                   :rows="10"
                   dataKey="_id" v-model:filters="filters" filterDisplay="menu" :loading="loading"
                   responsiveLayout="scroll"
                   :globalFilterFields="['teaching_name', 'closing_date', 'speakers_list', 'faculty', 'survey_name', 'number']">

          <template #header>
            <div class="flex justify-content-between">

              <Button type="button" icon="pi pi-filter-slash" :label="$t('my_requests.clear_filters')"
                      class="p-button-outlined"
                      @click="clearFilter()"/>
              <span class="p-ml-3 p-input-icon-left">
              <InputText class="p-ml-1" v-model="filters['global'].value" :placeholder="$t('my_requests.search')"/>
              <i class="p-ml-2 pi pi-search"/>
          </span>
              <Button icon="pi pi-check" @click="validateMassEvalRequests" v-if="mass_processing"
                      class="p-button p-button-success p-ml-5"
                      :label="$t('request_list.validate_mass_eval')"/>
              <Button icon="pi pi-times" @click="deleteMassEvalRequests" v-if="mass_processing"
                      class="p-button p-button-danger p-ml-2"
                      :label="$t('request_list.delete_mass_eval')"/>
            </div>
          </template>

          <template #empty>
            {{ $t('misc.empty') }}
          </template>

          <template #loading>
            {{ $t('misc.loading') }}
          </template>

          <Column field="number" filter-field="number" type="number" :sortable="true"
                  :header="$t('my_requests.columns.id')" style="max-width: 120px">
            <template #body="{data}">
              {{ data.number }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" fil type="text" class="p-column-filter" style="width: 40px"/>
            </template>
          </Column>

          <Column field="teaching_name" filter-field="teaching_name" :sortable="true" style="max-width: 200px"
                  :header="$t('my_requests.columns.teaching_names.title')">
            <template #body="{data}">
              {{ data.teaching_name }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                         placeholder="Search by teaching name"/>
            </template>
          </Column>

          <Column field="speakers_list" filter-field="speakers_list" style="max-width: 230px"
                  :header="$t('my_requests.columns.teachers')">
            <template #body="{data}">
              {{ data.speakers_list }}
            </template>

            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                         placeholder="Search by speakers"/>
            </template>
          </Column>

          <Column field="faculty" filter-field="faculty" :sortable="true" style="max-width: 200px"
                  :header="$t('my_requests.columns.faculty')">
            <template #body="{data}">
              {{ data.faculty }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                         placeholder="Search by faculty"/>
            </template>
          </Column>

          <Column field="academic_year" filter-field="academic_year" :sortable="true" style="max-width: 150px"
                  :header="$t('my_requests.columns.academic_year')">
            <template #body="{data}">
              {{ data.academic_year }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                         placeholder="Search by academic year"/>
            </template>
          </Column>

          <Column field="semester" filter-field="semester" :sortable="true" style="max-width: 150px"
                  :header="$t('my_requests.columns.semester')">
            <template #body="{data}">
              {{ data.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="semesters" option-value="value"
              option-label="label"
              placeholder="Select One" class="p-column-filter">

              </Dropdown>
            </template>
          </Column>

          <Column field="survey_name" filter-field="survey_name" :sortable="true" style="max-width: 250px"
                  :header="$t('my_requests.columns.survey_name')">
            <template #body="{data}">
              {{
                data.survey_name[$i18n.locale]
                || data.survey_name.default
                || data.survey_name
              }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" @input="filterCallback()"
                         placeholder="Search by survey name"/>
            </template>
          </Column>

          <Column field="survey_type" filter-field="survey_type" :sortable="true" style="max-width: 150px"
                  :header="$t('my_requests.columns.survey_type')">
            <template #body="{data}">
              <Tag :severity="data.survey_type === 'existing' ? 'info' : 'warning'"
                   rounded> {{ data.survey_type === 'existing' ? $t('misc.existing') : $t('misc.custom') }}
              </Tag>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="survey_types"
                        placeholder="Select One" class="p-column-filter">
                <template #option="slotProps">
                  <Tag :value="slotProps.option" :severity="slotProps.option === 'custom' ? 'warning' : 'info'">
                    {{ slotProps.option === 'existing' ? $t('misc.existing') : $t('misc.custom') }}
                  </Tag>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="closing_date" filter-field="closing_date" sort-field="closing_date_timestamp"
                  style="max-width: 130px"
                  :sortable="true" :header="$t('my_requests.columns.closing_date.title')">
            <template #body="{data}">
              {{ data.closing_date }}
            </template>
          </Column>

          <Column field="status" header="Status" filter-field="status.status_code" sort-field="status.status_code"
                  :sortable="true" style="max-width: 200px">
            <template #body="{data}">
              <Tag class="p-mt-1 p-ml-1" :severity="data.status?.class"
                   :value="$t('request_detail.status_' + data.status?.status_code)"></Tag>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" option-value="code"
                        option-label="label"
                        placeholder="Select One" class="p-column-filter">
                <template #option="slotProps">
                  <Tag :value="slotProps.option.code" :severity="slotProps.option.class">{{ slotProps.option.label }}
                  </Tag>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="specific_needs" header="Autres" style="max-width: 200px">
            <template #body="{data}">
              <Tag v-for="specific_need in data.specific_needs" class="p-mt-1 p-ml-1" :value="specific_need"
                   :severity="specific_needs_severity[specific_need]"></Tag>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="specific_needs"
                        placeholder="Select One" class="p-column-filter">
                <template #option="slotProps">
                  <Tag :value="slotProps.option" :severity="specific_needs_severity[slotProps.option]"/>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="actions" header="Actions">
            <template #body="{data}">
              <Button @click="$router.push({name: 'request_detail', params: {id: data._id}})" icon="pi pi-eye"
                      class="p-mr-2 p-button-info p-button-rounded left" text/>
              <Button v-if="is_admin" icon="pi pi-trash" class="p-button-danger p-button-rounded p-mr-2"
                      @click="deleteRequest(data._id)" text/>
            </template>
          </Column>

        </DataTable>
      </div>
    </TabPanel>
    <TabPanel v-if="is_admin">
      <template #header>
        <i class="pi pi-calendar p-mr-2"></i>
        <span>Calendrier</span>
      </template>
    </TabPanel>
    <TabPanel v-if="is_admin">
      <template #header>
        <i class="pi pi-file-excel p-mr-2"></i>
        <span>Export</span>
      </template>
      <RequestsReport :requests="requestsToExport"/>
    </TabPanel>
  </TabView>

  <!-- TODO hack to get calendar displayed properly -- remove ASAP  -->
  <TaskCalendar v-if="active === 1"></TaskCalendar>

</template>
<script>
import RequestService from '@/Services/request.service'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {generalhelper} from "@/helpers/generalhelper";
import ReportsService from "@/Services/reports.service";
import RequestsReport from "@/views/requests/RequestsReport";
import TaskCalendar from "@/views/admin/TaskCalendar";
import {requesthelper} from "@/helpers/requesthelper";

export default {
  name: 'requests-list',
  components: {RequestsReport, TaskCalendar},
  data() {
    return {
      requestList: [],
      requestsToExport: [],
      filters: null,
      loading: true,
      statuses: generalhelper.getStatuses(this.$t),
      acad_years: generalhelper.getAcadYears(),
      semesters: generalhelper.getSemesters(this.$t),
      survey_types: ['existing', 'custom'],
      specific_needs: ['< 5 jours', 'Synthèse requise', '< 60%'],
      specific_needs_severity: {'< 5 jours': 'warning', 'Synthèse requise': 'info', '< 60%': 'danger'},
      mass_request_ids: [],
      mass_processing: false,
      active: 0,
      is_admin: false,
      multi_sort_meta: []
    }
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.is_admin = sessionStorage.getItem('currentrole') === 'admin'
    this.is_editor = sessionStorage.getItem('currentrole') === 'editor'
    this.multi_sort_meta = [
      {field: 'closing_date_timestamp', order: -1},
    ]
    this.getRequests()
  },
  methods: {
    clearFilter() {
      this.initFilters()
      this.mass_processing = false
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        'number': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        'teaching_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'faculty': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'academic_year': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'semester': {value: null, matchMode: FilterMatchMode.EQUALS},
        'mass_eval_id': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'survey_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'survey_type': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        'status.status_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        },
        'speakers_list': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        },
        'closing_date': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]
        },
        'specific_needs': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
        }
      }
    },
    switchToExportTab(event) {
      this.active = event.index
      if (this.active === 2) {
        let requests_list = this.$refs.dt.value
        //send this requests_list to an API endpoint that will parse the data and return a CSV file
        ReportsService.getDetailedAcceptance(requests_list, true).then(result => {
          this.requestsToExport = result.data
        })
      }
    },
    extractMassRequestIdsFromRequestList() {
      this.requestList.forEach(request => {
        if (request.mass_eval_id !== undefined && !this.mass_request_ids.includes(request.mass_eval_id)) {
          this.mass_request_ids.push(request.mass_eval_id)
        }
      })
    },
    activateMassProcessing() {
      this.mass_processing = true
    },
    getRequests() {
      RequestService.getRequests(this.is_admin || this.is_editor ? '' : 'email=' + this.$store.getters['auth/userdata'].email)
        .then(r => {
          this.requestList = r.data
          if (this.is_admin || this.is_editor) {
            ReportsService.getSimpleGlobalAcceptance(this.requestList).then(result => {
              this.requestList = requesthelper.addSpecificNeedsToRequests(result.data, true)
            })
          }
          generalhelper.extract_speakers_as_list(this.requestList)
          generalhelper.add_closing_date(this.requestList, this.$i18n.locale)
          this.extractMassRequestIdsFromRequestList()
          this.loading = false;
        })
    },
    validateMassEvalRequests() {
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('my_requests.mass_processing.confirm_validate'),
        accept: () => {
          let mass_eval_id = this.filters.mass_eval_id.constraints[0].value
          let requests = this.requestList.filter(request => request.mass_eval_id === mass_eval_id)
          requests.forEach(async request => {
            request.status.status_code = 1 //validated
            request.status.class = 'success'
            await RequestService.updateRequest(request)
              .then(r => {
                request = r.data
              })
          })
          this.mass_processing = false
          this.$toast.add({severity: 'success', summary: this.$t('my_requests.mass_processing.success'), life: 3000})
        }
      })
    },
    deleteRequest(id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('misc.are_you_sure'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          RequestService.deleteRequest(id)
            .then(() => {
              this.$toast.add({severity: 'success', summary: 'Requête supprimée', life: 3000})
              this.getRequests()
            })
        }
      })
    },
    deleteMassEvalRequests() {
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('my_requests.mass_processing.confirm_delete'),
        accept: () => {
          let mass_eval_id = this.filters.mass_eval_id.constraints[0].value
          let requests = this.requestList.filter(request => request.mass_eval_id === mass_eval_id)
          //delete each request
          requests.forEach(async request => {
            await RequestService.deleteRequest(request._id)
              .then(() => {
                this.requestList = this.requestList.filter(r => r._id !== request._id)
              })
          })
          this.$toast.add({
            severity: 'success',
            summary: this.$t('my_requests.mass_processing_delete.success'),
            life: 3000
          })
          this.clearFilter()
        }
      })
    }
  }
}
</script>
<style scoped>
li {
  list-style: none;
}
</style>
