import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/params';

class TaskService {
  getParam(key) {
    return axios.get(API_URL + '/' + key)
  }
}

export default new TaskService();
