<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <h1>Confidentialité</h1>
  <p>Vous trouverez ci-dessous les informations liées à la conservation de vos données.</p>
  <a :href="links?.charter_url" target="_blank">Charte de confidentialité</a>
  <a :href="links?.terms_of_use" target="_blank">Conditions d'utilisation</a>
</template>
<script>
export default {
  name: 'Privacy',
  computed: {
    links() {
      return this.$store.state.params.links
    }
  },
}
</script>
