import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/faculties';

class FacultiesService {
  getFaculties() {
    return axios.get(API_URL);
  }

  getFaculty(id) {
    return axios.get(API_URL + '/' + id);
  }

  updateFaculty(id, data) {
    return axios.put(API_URL + '/' + id, data);
  }
}

export default new FacultiesService();
