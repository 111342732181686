import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/results';

class ResultsService {
  getResults(request_id) {
    return axios.get(API_URL + '/' + request_id);
  }
  getResultsCount(request_id) {
    return axios.get(API_URL + '/count/' + request_id);
  }
  updateResult(result) {
    return axios.put(API_URL + '/' + result._id, result);
  }
  deleteResult(eee_request_id, participant_email) {
    return axios.delete(API_URL + '/' + eee_request_id + '/' + participant_email + '/hard');
  }
  removeResultAnswer(eee_request_id, participant_email) {
    return axios.delete(API_URL + '/' + eee_request_id + '/' + participant_email + '/soft');
  }
}

export default new ResultsService();
