<template>
  <Toast/>
  <ConfirmPopup></ConfirmPopup>
  <Header></Header>
  <div class="body">
    <div id="nav"></div>
    <router-view/>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {axioshelper} from "@/helpers/axioshelper";

export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    let axhelp = new axioshelper(this.$store, this.$router)
    axhelp.addTokenToEveryRequest()
    axhelp.retryFailedAPICalls()
    this.$store.dispatch('params/setLinks')
  }
}
</script>

<style>
body {
  margin: 0;
}

.body {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 147px);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.p-grid {
  margin-right: 0 !important;
}

 .required:after {
   content: " *";
   color: red;
 }
</style>
