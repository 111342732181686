<template></template>
<script>

import store from "@/store";

export default {
  name: "Login",
  created() {
    store.dispatch('auth/login').then(() => {
      this.$router.push('/')
    })
  }
};
</script>
