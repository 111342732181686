<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'Admin',
}
</script>
