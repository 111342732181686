import SylviaService from "@/Services/sylvia.service"

export const sylvia = {
  namespaced: true,
  state: {
    teachingOptions: null,
    students: [],
    teachers: []
  },
  actions: {
    setTeachingOptions({commit}, {teacheruid, year}) {
      SylviaService.getTeachings(teacheruid, year)
        .then((response) => {commit('setTeachingOptions', response?.data)})
        .catch(error => {
          //TODO implement toast service (localstorage or store ?)
          console.log(error)
        })
    },
    setStudents({commit}, {courseid, acadyear, semester}) {
      if (courseid !== undefined && acadyear !== undefined && semester !== undefined) {
        SylviaService.getParticipants(courseid, acadyear, semester).then(response => {
          commit('setStudents', response.data.students)
        })
      }
    },
    clearStudents({commit}) {
      commit('clearStudents')
    },
    setTeachers({commit}) {
      SylviaService.getTeachers().then(response => {
        commit('setTeachers', response.data)
      })
    },
  },
  mutations: {
    setTeachingOptions(state, teachingOptions) {
      state.teachingOptions = teachingOptions
    },
    setStudents(state, participants) {
      participants?.map(participant => {
        state.students.push(participant.email)
      })
      state.students = [...new Set(state.students)]
    },
    clearStudents(state) {
      state.students = []
    },
    setTeachers(state, teachers) {
      state.teachers = teachers
    }
  },
}
