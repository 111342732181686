<template>
  <Menubar :model="items">
    <template #start>
      <router-link to="/"><img id="logounil" src="../assets/logounil.png" alt="logo UNIL"></router-link>
    </template>
    <template #end>
      <div>
        <a @click="change_language" class="p-mt-2 p-mr-2">
          <img id="lang"
               :src="require(`../assets/${this.$i18n.locale === 'fr' ? 'en' : 'fr'}.svg`)"
               alt="change language" title="change language">
        </a>
        <span v-if="roles.length !== 0" class="p-ml-2 p-mr-2">
          <dropdown :options="roles"  @change="update_current_role($event)" option-label="label" option-value="value" :placeholder="$t('misc.pickrole')"></dropdown>
        </span>
        <span>
          <a id="logout" @click="logout">{{ $t('misc.logout') }}</a></span>
      </div>
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar'

export default {
  name: 'Header',
  components: {
    Menubar
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    roles: {
      get() {
        const roles = this.$store.getters['auth/roles'].map(role => {
          return {label: this.$t('roles.' + role), value: role}
        })
        return roles
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        console.log('LOGOUT')
      })
    },
    change_language() {
      this.$i18n.locale = this.$i18n.locale === 'fr' ? 'en' : 'fr';
      localStorage.setItem('lang', this.$i18n.locale)
    },
    update_current_role(event) {
      sessionStorage.setItem('currentrole', event.value)
      this.$router.go()
    }
  }
}
</script>

<style scoped>

#logounil {
  width: 130px;
}

.p-menubar {
  background-color: #018CCC;
  color: white;
  border: 0;
  border-radius: 0;
}

#lang {
  width: 25px;
  margin-bottom: -5px;
}

#logout, #lang {
  cursor: pointer;
}

.p-dropdown {
  background: transparent;
  border: none;
}

:deep(.p-dropdown .p-dropdown-label.p-placeholder),
:deep(.p-dropdown .p-dropdown-trigger) {
  color: white;
}

</style>
