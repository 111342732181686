<template>
  <div>
    <Card>
      <template v-slot:title>{{ $t("form.step1.title") }}</template>
      <template v-slot:subtitle>{{ $t("form.step1.description") }}</template>
      <template v-slot:content>
        <div class="p-grid">
          <div class="p-fluid p-lg-4 p-md-12 p-sm-12">

            <div class="p-grid">
              <!--   year   -->
              <div class="p-field p-md-5">
                <label for="year" class="required"> {{ $t("form.step1.fields.year.label") }}</label>
                <Dropdown v-model="acadyear" id="year" :options="years" option-label="label" option-value="value"  @change="getTeachings()"/>
              </div>

              <!--   semester   -->
              <div class="p-field p-md-7">
                <label for="semester" class="required"> {{ $t("form.step1.fields.semester.label") }}</label>
                <SelectButton id="semester" v-model="semester" :options="semesters" optionLabel="name"
                              @change="setStudents"
                              optionValue="value" :class="{'p-invalid': validationErrors.semester && submitted}"/>
              </div>
            </div>

            <!--     teachers names     -->
            <div class="p-field" v-if="acadyear && semester">
              <label for="teacher"> {{ $t("form.step1.fields.teacher.label") }}
                <i class="pi pi-question-circle" v-tooltip="$t('request_detail.tooltips.teacher')"></i>
              </label>
              <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>

                <Dropdown v-model="teacher" :options="teachersNames" optionLabel="email" :filter="true"
                          :showClear="true" @change="getTeachings"></Dropdown>

              </div>
            </div>

            <!--     teaching name     -->
            <div class="p-field" v-if="acadyear && semester">
              <Message severity="info">{{ $t('misc.info_no_teaching') }}</Message>
              <label for="teachingname" class="required"> {{ $t("form.step1.fields.teachingname.label") }}</label>
              <Dropdown id="teachingname" :class="{'p-invalid': validationErrors.teachingname && submitted}"
                        v-model="teaching" :options="teachingOptions" optionLabel="name"
                        :editable="true"
                        :empty-message="$t('misc.no_data')"
                        :placeholder='$t("form.step1.fields.teachingname.placeholder")'
                        @change="updateTeachingData($event)"
                        @input="setTeachingName($event)"
              />

              <small v-show="validationErrors.teachingname && submitted"
                     class="p-error">{{ $t("form.step1.fields.teachingname.error") }}</small>
            </div>

            <!--   Courses   -->
            <div class="p-field" v-if="teaching">
              <label for="teachingname"> {{ $t("form.step1.fields.courses.label") }}</label>
              <MultiSelect v-model="selectedCourses" :options="filteredCourses" optionLabel="fullname"
                           :empty-message="$t('misc.no_data')"
                           :placeholder='$t("form.step1.fields.courses.placeholder")'
                           @change="updateSpeakersDatatable($event)"
              />
            </div>

            <!--    faculty     -->
            <div class="p-field" v-if="teaching">
              <label for="faculty" class="required">{{ $t("form.step1.fields.faculty.label") }}</label>

              <Dropdown id="faculty" :options=facultiesList option-label="name" option-value="name" v-model="faculty"
                        :class="{'p-invalid': validationErrors.faculty && submitted}"></Dropdown>

              <small v-show="validationErrors.faculty && submitted"
                     class="p-error">{{ $t("form.step1.fields.faculty.error") }}</small>
            </div>
          </div>

          <!--     speakers     -->
          <div class="p-fluid p-lg-8 p-md-12 p-sm-12" v-if="acadyear && semester && teaching && faculty">
            <label for="speakers" class="required"> {{ $t("form.step1.fields.speakers.label") }}
              <i class="pi pi-question-circle" v-tooltip="$t('request_detail.tooltips.speakers')"></i>
            </label>
            <SpeakersDatatable id="speakers" :speakers="speakers" :validationErrors="validationErrors"
                               @value-change="updateSpeakersList">
            </SpeakersDatatable>
          </div>
        </div>

      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <i></i>
          <Button :label='$t("misc.next")' @click="nextPage($event)" icon="pi pi-angle-right" iconPos="right"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import SpeakersDatatable from "@/components/form/SpeakersDatatable";
import FacultiesService from "@/Services/faculties.service";
import {generalhelper} from "@/helpers/generalhelper";

export default {
  name: 'Teaching',
  components: {
    SpeakersDatatable
  },
  computed: {
    acadyear: {
      get() {
        return this.$store.state.request.acadyear
      },
      set(value) {
        this.$store.commit('request/setAcadYear', value)
      }
    },
    faculty: {
      get() {
        return this.$store.state.request.faculty
      },
      set(value) {
        this.$store.commit('request/setFaculty', value)
      }
    },
    teacher: {
      get() {
        return this.$store.state.request.teacher
      },
      set(value) {
        this.$store.commit('request/setTeacher', value)
      }
    },
    teaching: {
      get() {
        return this.$store.state.request.teaching
      },
      set(value) {
        this.$store.commit('request/setTeaching', value)
        this.teachingname = value?.name
      }
    },
    courses: {
      get() {
        return this.$store.state.request.courses
      },
      set(value) {
        this.selectedCourses = value
        this.$store.commit('request/setCourses', value)
      }
    },
    curriculum: {
      get() {
        return this.$store.state.request.curriculum
      },
      set(value) {
        this.$store.commit('request/setCurriculum', value)
      }
    },
    teachingOptions: {
      get() {
        return this.$store.state.sylvia.teachingOptions
      },
      set(value) {
        this.$store.commit('sylvia/setTeachingOptions', value)
      }
    },
    semester: {
      get() {
        return this.$store.state.request.semester
      },
      set(value) {
        this.$store.commit('request/setSemester', value)
      }
    },
    teachersNames: {
      get() {
        return this.$store.state.sylvia.teachers
      },
    }
  },
  data() {
    return {
      teachingname: '',
      filteredCourses: [],
      selectedCourses: [],
      filteredTeachersNames: null,
      filteredFaculties: null,
      speakers: [],
      submitted: false,
      validationErrors: {},
      semesters: [
        {name: this.$t("form.step1.fields.semester.first"), value: 'P'},
        {name: this.$t("form.step1.fields.semester.second"), value: 'A'}
      ],
      facultiesList: [],
      years: []
    }
  },
  beforeCreate() {
    //populating teachers list
    this.$store.dispatch('sylvia/setTeachers')
  },
  mounted() {
    this.years = generalhelper.getAcadYears()
    FacultiesService.getFaculties().then(response => {
      this.facultiesList = response.data
    })
  },
  methods: {
    nextPage(event) {
      this.submitted = true;
      if (this.validateForm()) {
        let speakers_names = ''
        this.speakers.forEach((speaker) => {
          speakers_names += speaker.name + ', '
        })
        speakers_names = speakers_names.slice(0, -2)

        this.$confirm.require({
          target: event.currentTarget,
          message: this.$t('form.step1.next_step_confirmation', {speakers_names: speakers_names}),
          acceptLabel: this.$t('misc.yes'),
          rejectLabel: this.$t('misc.no'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.$emit('next-page', {
              formData: {
                teacher: this.teacher,
                academic_year: this.acadyear,
                semester: this.semester,
                teaching_name: this.teachingname,
                faculty: this.faculty,
                courses: this.courses,
                curriculum: this.curriculum,
                speakers: this.speakers
              },
              pageIndex: 0
            });
          },
          reject: () => {}
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('misc.error'),
          detail: this.$t('misc.missing_fields'),
          life: 3000
        });
      }
    }
    ,
    //TODO update validation
    validateForm() {

      !this.teachingname ? this.validationErrors['teachingname'] = true : delete this.validationErrors['teachingname']
      !this.faculty ? this.validationErrors['faculty'] = true : delete this.validationErrors['faculty']
      this.speakers.length === 0 ? this.validationErrors['speakers'] = true : delete this.validationErrors['speakers'];

      return !Object.keys(this.validationErrors).length;
    }
    ,
    getTeachings() {
      this.courses = []
      this.selectedCourses = []
      this.faculty = null
      this.curriculum = []
      this.teaching = null
      if (this.teacher?.username && this.acadyear) {
        this.$store.dispatch('sylvia/setTeachingOptions', {
          teacheruid: this.teacher.username,
          year: this.acadyear
        })
      }
    }
    ,
    updateTeachingData(event) {
      this.setStudents()
      this.speakers = []
      this.courses = []
      this.filteredCourses = event.value.courses
      this.filteredCourses?.forEach(course => {
        course.fullname = course.name + ' - ' + course.periodicity
      })
    }
    ,
    setTeachingName(event) {
      this.teachingname = event.target.value
    }
    ,
    setStudents() {
      if (this.teaching && this.acadyear && this.semester) {
        this.$store.dispatch('sylvia/clearStudents')
        this.$store.dispatch('sylvia/setStudents', {
          courseid: this.teaching.ensid,
          acadyear: this.acadyear,
          semester: this.semester
        })
      }
    }
    ,
    updateSpeakersDatatable(event) {
      this.$store.commit('request/setCourses', event.value)
      this.$store.commit('request/setCurriculum', event.value)

      this.speakers = []
      this.selectedCourses.forEach((course) => {
        course.speakers.forEach((speaker) => {
          if (speaker.email !== null) {
            this.speakers.push({
              name: speaker.firstname + ' ' + speaker.lastname,
              email: speaker.email,
              role: 'INTERV'
            })
          }
        })
        course.instructors.forEach((instructor) => {
          if (instructor.email !== null) {
            this.speakers.push({
              name: instructor.firstname + ' ' + instructor.lastname,
              email: instructor.email,
              role: 'RESP ENS'
            })
          }
        })
      })
      //remove duplicates
      this.speakers = Object.values(this.speakers.reduce((acc, cur) => Object.assign(acc, {[cur.email]: cur}), {}))
    }
    ,
    updateSpeakersList(speakersList) {
      this.speakers = speakersList
    }
  },
}
</script>
<style>
[id=curriculum] > div > ul {
  overflow: auto;
}
</style>
