import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/questionmodels';

class QuestionmodelsService {
  getQuestionModels() {
    return axios.get(API_URL);
  }

  createQuestionModel(data) {
    return axios.post(API_URL, data)
  }

  deleteQuestionModel(id) {
    return axios.delete(API_URL + '/' + id)
  }
}

export default new QuestionmodelsService();
