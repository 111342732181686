export class Validation {

  static returnIncorrectEmails(emails) {
    let incorrect_emails = []
    emails?.forEach(function (email) {
      if (!email.trim().toLowerCase().match('^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')) {
          incorrect_emails.push(email)
      }
    })
    return incorrect_emails
  }

  static isTargetAudienceValid(students, validation_errors, incorrect_emails) {
    validation_errors = {}
    incorrect_emails = this.returnIncorrectEmails(students)

    if (incorrect_emails.length !== 0)
      validation_errors['incorrectemails'] = true;
    else
      delete validation_errors['incorrectemails'];

    if (students?.length === 0)
      validation_errors['nostudent'] = true;
    else
      delete validation_errors['nostudent'];

    return {
      validation_errors: validation_errors,
      incorrect_emails: incorrect_emails,
      is_valid: !Object.keys(validation_errors).length
    };
  }
}
