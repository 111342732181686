<template>
  <h1 class="p-mb-5">{{ $t('admin.title') }}</h1>
  <div class="p-grid p-jc-center">

    <Card class="p-lg-3 p-sm-12 p-ml-2">
      <template #title>
        {{ $t('admin.massevaluation.title') }}
      </template>
      <template #content>
        <p> {{ $t('admin.massevaluation.description') }}</p>
      </template>
      <template #footer>
        <Button @click="$router.push({name: 'massEvaluation'})" icon="pi pi-calendar"
                class="p-button-info p-button-rounded p-ml-2"
                :label="$t('admin.massevaluation.buttonlabel')"/>
      </template>
    </Card>

  </div>
</template>
<script>
export default {
  name: 'index'
}
</script>
