import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/sylviaacad';

class SylviaService {
  getTeachings(teacheruid, year) {
    return axios.get(API_URL + '/teachings/' + teacheruid + '/' + year)
  }

  getParticipants(courseid, year, semester) {
    return axios.get(API_URL + '/participants/' + courseid + '/' + year + '/' + semester)
      .catch(error => {
        console.log(error)
      })
  }

  getFaculties() {
    return axios.get(API_URL + '/faculties')
  }

  getTeachers() {
    return axios.get(API_URL + '/teachers')
  }
}

export default new SylviaService();
