<template>
  <h1>{{ $t('admin.massevaluation.title') }}</h1>
  <h3 class="p-mb-6">{{ $t('admin.massevaluation.description') }}</h3>

  <div class="p-grid p-col-12">

    <Accordion class="p-col-12" :multiple="false" :activeIndex="0">
      <AccordionTab header="Liste des enseignements">
        <!--    File upload    -->
        <div class="p-mb-3">
          <FileUpload mode="advanced" name="file[]" :customUpload="true" :show-upload-button="false"
                      @uploader="onUpload" :auto="true"
                      accept="text/csv"
                      :maxFileSize="15000000"/>
          <small v-show="validationErrors.teachings && submitted"
                 class="p-error">{{ validationErrors.teachings }}</small>
        </div>

        <!--------- Teachings ------------>
        <DataTable v-if="teachings" :value="teachings"
                   :paginator="true"
                   :rows="20"
                   ref="dt"
                   :loading="loading">

          <Column field="Enseignement" header="Enseignement" sortable></Column>
          <Column field="Horaire(s)" header="Niveau" sortable></Column>
          <Column field="Responsable Email" header="Responsable" sortable></Column>
          <Column field="Intervenants (non assistants)" header="Intervenants (non assistants)" sortable></Column>

          <template #empty>
            No records found.
          </template>

        </DataTable>

      </AccordionTab>

      <AccordionTab header="Configuration des questionnaires">
        <div class="p-grid p-mt-3">
          <div class="p-col-4 p-fluid">
            <Dropdown v-model="faculty" :options="faculties" optionLabel="name"
                      placeholder="Select a faculty" :class="{'p-invalid': validationErrors.faculty && submitted}"/>
            <small v-show="validationErrors.faculty && submitted"
                   class="p-error">{{ validationErrors.faculty }}</small>
          </div>

          <div class="p-col-4 p-fluid">
            <Dropdown placeholder="Select a year" v-model="acadyear" :options="years" :class="{'p-invalid': validationErrors.acadyear && submitted}"/>
            <small v-show="validationErrors.acadyear && submitted"
                   class="p-error">{{ validationErrors.acadyear }}</small>
          </div>

          <div class="p-col-4">
            <label for="semester"> {{ $t("form.step1.fields.semester.label") }}</label>
            <SelectButton id="semester" v-model="semester" :options="semesters" optionLabel="name"
                          optionValue="value" :class="{'p-invalid': validationErrors.semester && submitted}"/>
            <small v-show="validationErrors.semester && submitted"
                   class="p-error">{{ validationErrors.semester }}</small>
          </div>
        </div>

        <!--------- Dates ------------>
        <div class="p-grid p-fluid p-mt-3">
          <div class="p-field p-col-4">
            <label>{{ $t("form.step3.fields.sendingdate.label") }}</label>
            <Calendar v-model="sending_date" :showTime="true" :class="{'p-invalid': validationErrors.sending_date && submitted}"/>
            <small v-show="validationErrors.sending_date && submitted"
                   class="p-error">{{ validationErrors.sending_date }}</small>
          </div>

          <div class="p-field p-col-4 p-as-end">
            <label>{{ $t("form.step3.fields.closingdate.label") }}</label>
            <Calendar v-model="closing_date" :showTime="true" :min-date="sending_date" :class="{'p-invalid': validationErrors.closing_date && submitted}"/>
            <small v-show="validationErrors.closing_date && submitted"
                   class="p-error">{{ validationErrors.closing_date }}</small>
          </div>

          <div class="p-field p-col-4 p-as-end">
            <label>ID du modèle de questionnaire</label>
            <Dropdown :options="surveys" optionLabel="_id" v-model="survey"
                      :class="{'p-invalid': validationErrors.survey && submitted}"></Dropdown>
            <small v-show="validationErrors.survey && submitted"
                   class="p-error">{{ validationErrors.survey }}</small>
          </div>

        </div>
      </AccordionTab>
    </Accordion>

  </div>


  <Button label="Créer les demandes d'évaluation" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-sm"
          @click="createRequests"/>


</template>
<script>
import SylviaService from '@/Services/sylvia.service'
import FacultiesService from "@/Services/faculties.service";
import RequestService from '@/Services/request.service'
import SurveyService from "@/Services/survey.service";

export default {
  name: 'mass-evaluation',
  data() {
    return {
      active: 0,
      validationErrors: {},
      submitted: false,
      faculties: [],
      faculty: null,
      teachings: null,
      acadyear: null,
      semester: null,
      semesters: [
        {name: this.$t("form.step1.fields.semester.first"), value: 'P'},
        {name: this.$t("form.step1.fields.semester.second"), value: 'A'}
      ],
      years: [],
      loading: false,
      sending_date: null,
      closing_date: null,
      survey: null,
      surveys: [],
    }
  },
  beforeMount() {
    let current_year = new Date().getFullYear()
    this.years = [current_year - 2, current_year - 1, current_year]
    FacultiesService.getFaculties().then(result => {
      this.faculties = result.data
    })
    SurveyService.getSurveys('is_for_mass_evaluation=true').then(response => {
      this.surveys = response.data
    })
  },
  methods: {
    createRequests() {
      this.submitted = true

      if (this.validateForm()) {
        let data = {
          teachings: this.teachings,
          faculty: this.faculty,
          semester: this.semester,
          acadyear: this.acadyear,
          sending_date: this.sending_date,
          closing_date: this.closing_date,
          original_survey: this.survey.json_definition
        }

        RequestService.createMassEvaluation(data).then(result => {
          this.$toast.add({severity: 'success', summary: 'Demandes de conseil créées', life: 3000})
          window.history.go(-2);
        })
      }
    },

    onUpload(event) {
      this.$papa.parse(event.files[0], {
        skipEmptyLines: true,
        header: true,
        complete: (teachings) => {
          teachings.data.forEach(teaching => {
            teaching['Courriels Intervenants'] = teaching['Intervenants (non assistants)'].match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)?.join(', ') ?? ''
          })
          this.teachings = teachings.data
        }
      });

    },

    validateForm() {
      this.validationErrors = {}

      if(this.teachings === null) this.validationErrors.teachings = 'Le fichier contient une erreur.'
      if(this.faculty === null) this.validationErrors.faculty = 'La faculté n\'est pas renseignée'
      if(this.semester === null) this.validationErrors.semester = 'Le semestre n\'est pas renseigné';
      if(this.acadyear === null) this.validationErrors.acadyear = 'L\'année académique n\'est pas renseignée';
      if(this.sending_date === null) this.validationErrors.sending_date = 'La date d\'envoi n\'est pas renseignée';
      if(this.closing_date === null) this.validationErrors.closing_date = 'La date de clôture n\'est pas renseignée';
      if(this.survey === null) this.validationErrors.survey = 'Le questionnaire n\'est pas renseigné';

      const result = Object.keys(this.validationErrors).length === 0
      !result ? this.$toast.add({severity: 'error', summary: 'Veuillez renseigner les champs manquants', life: 3000}) : null

      return result
    }
  }
}
</script>
<style>
</style>
