<template>
  <h3>{{ $t('admin.calendar.description') }}</h3>
  <div id="calendar">
    <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import frLocale from "@fullcalendar/core/locales/fr";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import TaskService from '@/Services/task.service'

export default {
  name: 'TaskCalendar',
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridDay',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay' // user can switch between the two
        },
        events: (info, successCallback) => {
          let tasks = []
          TaskService.getTasksByDateInterval(encodeURIComponent(info.startStr), encodeURIComponent(info.endStr)).then(result => {
            Object.values(result.data).forEach(task => {
              tasks.push({
                title: this.$t('request_detail.tasks.' + task.type + '.label') + ' - ' + task.eee_request.number + ' - ' + task.eee_request.teaching_name,
                start: new Date(task.date),
                url: '/requests/' + task.eee_request_id + '/detail'
              })
            })
            successCallback(tasks)
          })
        },
        locale: this.$i18n.locale === 'fr' ? frLocale : null,
      },
    }
  },
}
</script>
<style scoped>
[id='calendar'] {
  padding: 30px;
}
</style>
