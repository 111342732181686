import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/reports';

class ReportsService {
  /**
   * Returns the acceptance rate for every matrix question of the given list of requests
   * @param {Array} request_list
   * @param {Boolean} status_restriction - If true, only the requests with status "Finished" will be considered
   * @returns {Promise<AxiosResponse<any>>}
   */
  getDetailedAcceptance(request_list, status_restriction) {
    return axios.post(API_URL + '/acceptancerates/detailed/' + status_restriction, request_list)
  }

  /**
   * Returns the simple global acceptance rate for each request of the given list
   * @param {Array} request_list
   * @param {Boolean} status_restriction - If true, only the requests with status "Finished" and "Results_processing" will be considered
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSimpleGlobalAcceptance(request_list, status_restriction = true) {
    return axios.post(API_URL + '/acceptancerates/simple/' + status_restriction, request_list)
  }
}

export default new ReportsService();
