<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <h1>{{ $t('request_results_processing.title') + ' - no ' + request?.number}}</h1>
  <p>{{ request?.teaching_name }} - {{ request?.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }} {{ request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year }}</p>
  <p>{{ request?.speakers.map(speaker => speaker.name).join(', ') }}</p>

  <Toolbar>
    <template #start>
      <Button v-if="current_role === 'admin' && status?.status_code === 3" :label="$t('request_results_processing.send_results')" icon="pi pi-envelope" class="p-button-success p-mr-2"
              @click="sendResults(true)"/>
      <Button v-if="current_role === 'admin' && status?.status_code === 3" :label="$t('request_results_processing.send_results_without_notification')" icon="pi pi-check" class="p-button-warning p-mr-2"
              @click="sendResults(false)"/>
      <Button label="Export CSV des réponses" icon="pi pi-file-excel" @click="$router.push({name: 'request_results_export'})"></Button>
    </template>

    <template #end>
    </template>
  </Toolbar>

  <TabView :active-index="active" @tab-click="activate_results">
    <TabPanel header="Données quantitatives">
      <h4> {{ $t('request_results.participants') }} : {{ survey_results.length }} </h4>
      <p>Appréciation globale : {{ displayedGlobalAcceptancePercentage }}%</p>
      <DataTable :value="matrix_questions_answers" showGridlines stripedRows responsiveLayout="scroll">
        <template #header>
          <div style="text-align:left">
            <MultiSelect :modelValue="selected_matrix_questions_columns" :options="matrix_questions_columns"
                         optionLabel="title" @update:modelValue="onToggle"
                         placeholder="Select Columns" style="width: 20em"/>
          </div>
        </template>
        <Column field="total" header=""></Column>
        <Column v-for="question of selected_matrix_questions_columns" :field="question.hash"
                :header="question.title"></Column>
      </DataTable>
    </TabPanel>

    <TabPanel header="Données qualitatives">

      <TabView :active-index="active_comments_tab" id="qualitative-data">
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-eye"></i>
              <span class="font-bold white-space-nowrap"> Texte affiché</span>
            </div>
          </template>
          <DataTable :value="comments_list" showGridlines stripedRows editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table">
            <Column v-for="col of open_questions_list" :class="'p-col-' + 12/open_questions_list.length" :key="col.name" :field="col.name+'_cleaned'" :header="col.title[$i18n.locale] ?? col.title">
              <template #editor="{ data, field }">
                <Textarea autoResize cols="80" v-model="data[field]" autofocus/>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-eye-slash"></i>
              <span class="font-bold white-space-nowrap"> Texte original</span>
            </div>
          </template>
          <DataTable :value="comments_list" showGridlines stripedRows>
            <Column v-for="col of open_questions_list" :class="'p-col-' + 12/open_questions_list.length" :key="col.name" :field="col.name+'_original'" :header="col.title[$i18n.locale] ?? col.title"></Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </TabPanel>

    <TabPanel v-if="current_role === 'admin'" header="Liste des étudiants">
      <p><b>{{ reminders.length }} rappel(s) pour cette demande : </b></p>
      <ul>
        <li v-for="reminder in reminders"><i class="pi pi-envelope"></i> {{ generalHelper.formatDate(reminder.date) }} </li>
      </ul>

      <div class="p-col-12">
        <h4>{{ $t('request_detail.answer_rate') }} :
          {{ Math.ceil(nb_answers / request?.students.length * 100) }}%
          ({{ nb_answers }} / {{ request?.students.length }})
        </h4>
      </div>
      <DataTable :value="participants" stripedRows>
        <Column field="email" header="Email"></Column>
        <Column field="has_answered" header="A répondu"></Column>
        <Column field="actions" header="Actions">
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-2" :label="$t('request_results_processing.delete_participant_answers')" @click="deleteUserAnswers(slotProps.data)"/>
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" :label="$t('request_results_processing.delete_participant')" @click="deleteUser(slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </TabPanel>
  </TabView>
</template>

<script>
import RequestResults from "@/views/requests/RequestResults";
import RequestService from "@/Services/request.service";
import ResultsService from "@/Services/results.service";
import {generalhelper as generalHelper, generalhelper} from "@/helpers/generalhelper";
import {useRoute} from "vue-router";
import ReportsService from "@/Services/reports.service";

export default {
  name: 'request-results-editing',
  computed: {
    generalHelper() {
      return generalHelper
    }
  },
  components: {
    RequestResults
  },

  mounted() {
    this.current_role = sessionStorage.getItem('currentrole') ?? ''
    this.request_id = useRoute().params.id
    RequestService.getRequest(this.request_id).then(res => {
      this.request = res.data.result
    })
    ResultsService.getResultsCount(this.request_id).then(response => {
      this.nb_answers = response.data
    })
    this.getData()
    this.getParticipants()
    //switch tab in comments using keyboard arrows
    document.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowRight') {
        this.active_comments_tab = 1
      } else if (event.key === 'ArrowLeft') {
        this.active_comments_tab = 0
      }
    })
  },
  data() {
    return {
      current_role: '',
      request_id: null,
      request: null,
      nb_answers: 0,
      status: null,
      survey: null,
      survey_results: [],
      open_questions_list: [],
      matrix_questions_list: [],
      matrix_questions_answers: [],
      matrix_questions_columns: [],
      selected_matrix_questions_columns: [],
      totalRow: {},
      globalAcceptancePercentage: 0,
      displayedGlobalAcceptancePercentage: 0,
      comments_list: [],
      comments_array: [],
      show_results: false,
      active: 0,
      active_comments_tab: 0,
      reminders: [],
      participants: [],
    }
  },
  methods: {
    getCommentQuestions() {
      JSON.parse(this.survey).pages.forEach(page => {
        page.elements.forEach(question => {
          if (question.type === 'comment') {
            this.open_questions_list.push(question)
          } else if (question.type === 'matrix') {
            this.matrix_questions_list.push(question)
          }
        })
      })
    },
    /**
     * Creates an array with answers to matrix questions in the following format :
     * [
     *  [id => participant_1_id, "hash(question_1_title)"=> answer_to_q1, ... , "hash(question_n_title)"=> answer_to_qn],
     *  ...,
     *  [id => participant_p_id, "hash(question_1_title)"=> answer_to_q1, ... , "hash(question_n_title)"=> answer_to_qn],
     * ]
     * The hash is mandatory for allowing Datatable to match the columns with the rows (doesn't work with question titles)
     */
    getMatrixQuestionsAnswers() {
      this.survey_results.forEach(result => {
        let answer = {}
        answer.id = result._id
        this.matrix_questions_list.forEach(questiongroup => {
          let questions = result.json_definition[questiongroup.name] ?? {}
          Object.keys(questions).forEach(question_title => {
            this.matrix_questions_columns.push({
              title: question_title,
              hash: generalhelper.getHash(question_title).toString()
            })
            answer[generalhelper.getHash(question_title)] = questions[question_title]
          })
        })
        this.matrix_questions_answers.push(answer)
      })
      this.matrix_questions_columns = generalhelper.getUniqueValuesBy(this.matrix_questions_columns, 'hash')
    },
    parseComments() {
      this.survey_results.forEach(result => {
        let nb_questions_added = 0
        let comment = {
          id: result._id,
        }
        this.open_questions_list.forEach(question => {
          if (result.original_json_definition && JSON.parse(result.original_json_definition)[question.name] !== undefined) {
            comment[question.name + '_original'] = JSON.parse(result.original_json_definition)[question.name]
            comment[question.name + '_cleaned'] = result.json_definition[question.name]
            nb_questions_added++
          }
        })
        if (nb_questions_added !== 0) {
          this.comments_list.push(comment)
        }
      })
    },
    /**
     * Adds a row to the results tab with totals for each column
     */
    computeTotals() {
      ReportsService.getDetailedAcceptance([this.request], false).then(res => {
        res.data.columns.forEach(column => {
          const key = generalhelper.getHash(column.title).toString()
          const value = res.data.data[this.request_id][column.hash]
          this.totalRow[key] = value === null ? null : value + '%'
        })
        this.totalRow.total = this.$t('request_results.results_processing.total')
        this.matrix_questions_answers.push(this.totalRow)
        this.computeGlobalAcceptancePercentage()
      })
    },
    computeGlobalAcceptancePercentage() {
      let total_considered_columns = 0
      this.matrix_questions_columns.forEach(column => {
        if (this.totalRow[column.hash] !== undefined && this.totalRow[column.hash] !== null) {
          this.globalAcceptancePercentage += Number(this.totalRow[column.hash].slice(0, -1))
          total_considered_columns += 1
        }
      })
      this.globalAcceptancePercentage = Math.round(this.globalAcceptancePercentage / total_considered_columns)
      this.displayedGlobalAcceptancePercentage = this.globalAcceptancePercentage
    },
    getParticipants() {
      RequestService.getParticipants(this.request_id).then(res => {
        this.participants = res.data
        this.participants.forEach(participant => {
          participant.has_answered = participant.has_answered ? '✅' : '❌'
        })
      })
    },
    onCellEditComplete(event) {
      let result = this.survey_results.find(el => el._id === event.newData.id)
      if (typeof result.json_definition === 'string') {
        result.json_definition = JSON.parse(result.json_definition)
      }

      Object.keys(event.newData).forEach(key => {
        if (key !== 'id' && !key.includes('_original')) {
          result.json_definition[key.replace('_cleaned', '')] = event.newData[key]
        }
      })
      result.json_definition = JSON.stringify(result.json_definition)
      ResultsService.updateResult(result).then(() => {
        ResultsService.getResults(this.request_id).then(data => {
          this.survey_results = []
          this.comments_list = []
          data.data.forEach(result => {
            if (result.json_definition) {
              result.json_definition = JSON.parse(result.json_definition)
              this.survey_results.push(result)
            }
          })
          this.parseComments()
        })

        this.$toast.add({
          severity: 'success',
          summary: 'Comment saved',
          life: 3000
        })
      })
    },
    onToggle(value) {
      this.selected_matrix_questions_columns = this.matrix_questions_columns.filter(col => value.includes(col))
      this.displayedGlobalAcceptancePercentage = 0
      let total_considered_columns = 0
      this.selected_matrix_questions_columns.forEach(column => {
        if (this.totalRow[column.hash] === null) return
        this.displayedGlobalAcceptancePercentage += Number(this.totalRow[column.hash].slice(0, -1))
        total_considered_columns += 1
      })

      this.displayedGlobalAcceptancePercentage = total_considered_columns === 0
        ? this.globalAcceptancePercentage
        : Math.round(this.displayedGlobalAcceptancePercentage / total_considered_columns)
    },
    getData() {
      this.survey_results = []
      this.open_questions_list = []
      this.matrix_questions_list = []
      this.matrix_questions_answers = []
      this.matrix_questions_columns = []
      this.selected_matrix_questions_columns = []
      this.comments_list = []

      Promise.all([
        ResultsService.getResults(this.request_id),
        RequestService.getRequest(this.request_id)
      ])
        .then(data => {
          data[0].data.forEach(result => {
            if (result.json_definition) {
              result.json_definition = JSON.parse(result.json_definition)
              this.survey_results.push(result)
            }
          })
          this.survey = data[1].data.result.original_survey
          this.status = data[1].data.result.status
          this.getCommentQuestions()
          this.getMatrixQuestionsAnswers()
          this.parseComments()
          this.computeTotals()
        })
      RequestService.getRequest(this.request_id).then(res => {
        res.data.result.steps.forEach(step => {
          if (step.type === 'remind') {
            this.reminders.push(step)
          }
        })
      })
    },
    sendResults(notify_teachers) {
      /**
       * status_code : 4 corresponds to status RESULTS_AVAILABLE
       */
      //Require confirmation
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('misc.are_you_sure'),
        accept: () => {
          RequestService.changeRequestStatus(this.request_id, 4, this.globalAcceptancePercentage, notify_teachers).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('request_results_processing.send_results_confirmation'),
              life: 3000
            })
            this.$router.push({'name': 'request_results'})
          })
        }
      })

    },
    activate_results(event) {
      if (event.index === 2) {
        this.show_results = true
      }
    },
    deleteUserAnswers(participant) {
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('misc.are_you_sure'),
        accept: () => {
          ResultsService.removeResultAnswer(this.request_id, participant.email).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('request_results_processing.delete_participant_answers_confirmation'),
              life: 3000
            })
            this.getParticipants()
          })
        }
      })
    },
    deleteUser(participant) {
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('misc.are_you_sure'),
        accept: () => {
          ResultsService.deleteResult(this.request_id, participant.email).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('request_results_processing.delete_participant_confirmation'),
              life: 3000
            })
            this.getParticipants()
          })
        }
      })
    }
  }
}
</script>
<style>
td {
  word-break: break-word;
}

#qualitative-data .p-tabview-panels {
  overflow: scroll;
  height: 800px;
}
</style>
