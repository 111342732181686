import ParamsService from "@/Services/params.service";

export const params = {
  namespaced: true,
  state: {
    links: null
  },
  actions: {
    setLinks({commit}) {
      ParamsService.getParam('links').then(response => {
        commit('setLinks', response.data.value)
      })
    },
  },
  mutations: {
    setLinks(state, links) {
      state.links = links
    },
  },
}
