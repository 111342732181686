<template>
  <div class="footer p-jc-center">
    <Button @click="$router.push('/contact')" class="p-button-link"
            :label="$t('homepage.help.button_label')"/>
    <Button @click="$router.push('/privacy')" class="p-button-link"
            :label="$t('homepage.privacy.button_label')"/>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.footer {
  display: flex;
  background-color: #018CCC;
  color: white;
  border: 0;
  border-radius: 0;
  height: 60px;
  margin-top: 20px;
  padding: 15px;
}

:deep(.p-button.p-button-link) {
  color: white;
}
</style>
