<template>
  <div class="container">
    <header class="jumbotron">
      <h3>{{ currentUser.name }}</h3>
    </header>
    <p><strong>{{ currentUser.email }}</strong></p>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.userdata
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
