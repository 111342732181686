import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/tasks';

class TaskService {
  getTasksByDateInterval(start, end) {
    return axios.get(API_URL + '?start=' + start + '&end=' + end)
  }
}

export default new TaskService();
