import axios from "axios";

export class axioshelper {

  $store = null

  constructor(store, router) {
    this.$store = store
    this.$router = router
  }

  addTokenToEveryRequest() {
    axios.interceptors.request.use(
      (config) => {
        const token = this.$store.getters['auth/token']
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  retryFailedAPICalls() {
    axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        sessionStorage.removeItem('token')
        if (error.config.url.includes('eeerequests') && error.config.url.method === 'post') {
          sessionStorage.setItem('retryRequest', JSON.stringify(originalRequest))
        }
        this.$router.push('/login')
      }
      return Promise.reject(error);
    });
  }

}


// Response interceptor for API calls
