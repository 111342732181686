import {SurveyCreatorModel} from "survey-creator-core";
import {surveyLocalization} from "survey-core";
import { slk } from "survey-core";
import "survey-creator-core/survey-creator-core.i18n";

export class surveyhelper {
  static createSurveyCreatorModel(creatorOptions, toast = null) {
    surveyLocalization.defaultLocale = "fr";
    const creator = new SurveyCreatorModel(creatorOptions);
    creator.locale = 'fr';
    creator.toolbox.allowExpandMultipleCategories = true;
    creator.toolbox.showCategoryTitles = true;
    creator.toolbox.forceCompact = false;
    //hide questions of type "text"
    creator.toolbox.removeItem("text");
    slk("YjRmNzU3ZmUtMDJlZS00Y2EyLWJkYjktMTk1ZDIyZWY2YjlkOzE9MjAyNC0wOS0yOCwyPTIwMjQtMDktMjgsND0yMDI0LTA5LTI4")

    this.addDefaultMatrixQuestionChoices(creator);

    return creator;
  }

  static addDefaultMatrixQuestionChoices(creator) {
    //on matrix question add, add default choices
    creator.onQuestionAdded.add(function (_, options) {
      const question = options.question;
      const type = question.getType();
      if (type === "matrix") {
        question.columns = [
          {
            "value": "Non",
            "text": {
              "en": "No",
              "fr": "Non"
            }
          },
          {
            "value": "Plutôt non",
            "text": {
              "en": "Rather no",
              "fr": "Plutôt non"
            }
          },
          {
            "value": "Plutôt oui",
            "text": {
              "en": "Rather yes",
              "fr": "Plutôt oui"
            }
          },
          {
            "value": "Oui",
            "text": {
              "en": "Yes",
              "fr": "Oui"
            }
          },
          {
            "value": "Sans avis",
            "text": {
              "en": "No opinion",
              "fr": "Sans avis"
            }
          },
          {
            "value": "Pas de réponse",
            "text": {
              "en": "No answer",
              "fr": "Pas de réponse"
            }
          }
        ];
      }
    });
  }
}
