<template>
  <div>
    <Card>
      <template v-slot:title>{{ $t("form.step2.title") }}</template>

      <template v-slot:subtitle>{{ $t("form.step2.description") }}</template>

      <template v-slot:content>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field">
            <FileUpload :key="fileuploadkey" mode="basic" id="studentsfile" accept=".xlsx, .xls, .csv, .txt"
                        :maxFileSize="1000000" :chooseLabel='$t("form.step2.fields.student_list.import_button_label")' auto
                        :customUpload="true" @uploader="onFileSelect">
            </FileUpload>
          </div>
          <i id="tooltip" class="pi pi-question-circle p-ml-2" v-tooltip="$t('request_detail.tooltips.students_import')"></i>

          <div class="p-field p-col-12">
            <Chips v-model="this.$store.state.sylvia.students" separator="\n"
                   :class="{'p-invalid': (validationErrors.nostudent || validationErrors.incorrectemails) && submitted}"/>
          </div>

          <small v-show="validationErrors.nostudent && submitted"
                 class="p-error">{{ $t("form.step2.fields.student_list.error1") }}</small>
          <small v-show="validationErrors.incorrectemails && submitted"
                 class="p-error">{{ $t("form.step2.fields.student_list.error2") }}
            <ul id="incorrectemails">
              <li v-for="email in incorrectemails">
                {{ email }}
              </li>
            </ul>
          </small>

        </div>
      </template>

      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button :label='$t("misc.back")' @click="prevPage()" icon="pi pi-angle-left"/>
          <Button :label='$t("misc.next")' @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import {Validation} from "@/helpers/validation";

export default {
  name: 'Targetaudience',
  computed: {
    students: {
      get() {
        return this.$store.state.sylvia.students
      },
      set(value) {
        this.$store.commit('sylvia/setStudents', value)
      }
    },
  },
  data() {
    return {
      validationErrors: {},
      submitted: false,
      fileuploadkey: 0,
      incorrectemails: []
    }
  },
  methods: {
    nextPage() {
      this.submitted = true;
      if (this.validateForm()) {
        this.$store.commit('request/setStudents', this.students)
        this.$emit('next-page', {
          formData: {
            students: this.students,
          }, pageIndex: 1
        });
      }
    },
    prevPage() {
      this.$emit('prev-page', {pageIndex: 1});
    },
    onFileSelect(event) {
      let regex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;
      if(event.files[0].type === "text/csv" || event.files[0].type === "text/plain") {
        let reader = new FileReader();
        reader.readAsText(event?.files[0] ?? null);
        reader.onload = () => {
          let rows = reader.result.match(regex)
          rows.forEach(row => this.students.push(row))
        }
      }

      let xlsxfile = event?.files[0] ?? null;
      readXlsxFile(xlsxfile).then((rows) => {
        rows.forEach(row => {
          let email = row.toString().match(regex)
          if (email !== null) {
            this.students.push(email[0])
          }
        })
      })
      this.fileuploadkey++
    },
    validateForm() {
      let validation = Validation.isTargetAudienceValid(this.students, this.validationErrors, this.incorrectemails)
      this.incorrectemails = validation.incorrect_emails
      this.validationErrors = validation.validation_errors

      return validation.is_valid
    }
  }
}
</script>

<style scoped>
::v-deep(.p-chips .p-chips-multiple-container .p-chips-token) {
  margin-top: 5px;
}

#incorrectemails {
  text-align: left;
}

#tooltip {
  padding-top: 10px;
}
</style>
