<template>
  <ConfirmPopup></ConfirmPopup>

  <h1>{{ $t('config.facultysurveylink.title') }}</h1>
  <h3>{{ $t('config.facultysurveylink.description') }}</h3>

  <div class="p-mt-5 p-grid p-jc-center">
    <Dropdown v-model="selectedFaculty" @change="updateSurveyList" :options="faculties" optionLabel="name"
              data-key="_id" placeholder="Sélectionnez une faculté" class="p-col-3"/>

    <MultiSelect display="chip" v-model="selectedSurveys" @change="saveSelection"
                 :options="surveysOptions" empty-message="Sélectionnez d'abord une faculté"
                 class="p-ml-3 p-col-8"/>
  </div>

</template>
<script>
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-creator-core/survey-creator-core.min.css";
import FacultiesService from "@/Services/faculties.service";
import SurveyService from "@/Services/survey.service";


export default {
  name: "faculties-surveys-link-manager",
  data() {
    return {
      faculties: [],
      selectedFaculty: null,
      surveysOptions: [],
      selectedSurveys: []
    }
  },
  mounted() {
    FacultiesService.getFaculties().then(faculties => {
      this.faculties = faculties.data
    })
  },
  methods: {
    updateSurveyList() {
      SurveyService.getSurveys('is_visible=true').then(surveys => {
        this.surveysOptions = surveys.data.map(survey => survey._id)
        this.selectedSurveys = this.selectedFaculty?.surveys
      })
    },
    saveSelection() {
      FacultiesService.updateFaculty(this.selectedFaculty._id, {surveys: this.selectedSurveys}).then(faculty => {
        this.$toast.add({
          severity: 'success',
          summary: 'Succès',
          detail: 'Les liens ont été mis à jour',
          life: 3000
        });
        this.selectedFaculty = faculty.data
      })
    }
  }
}
</script>
<style scoped>

</style>
