<template>
  <h1 class="p-mb-5">{{ $t('config.title') }}</h1>
  <div class="p-grid p-jc-center">

    <Card class="p-lg-3 p-sm-12 p-ml-2">
      <template #title>
        {{ $t('config.questionmodels.title') }}
      </template>
      <template #content>
        <p> {{ $t('config.questionmodels.description') }}</p>
      </template>
      <template #footer>
        <Button @click="$router.push({name: 'questionmodels'})" icon="pi pi-cog" class="p-button-info p-button-rounded"
                :label="$t('config.questionmodels.buttonlabel')"/>
      </template>
    </Card>

    <Card class="p-lg-3 p-sm-12 p-ml-2">
      <template #title>
        {{ $t('config.surveymodels.title') }}
      </template>
      <template #content>
        <p> {{ $t('config.surveymodels.description') }}</p>
      </template>
      <template #footer>
        <Button @click="$router.push({name: 'surveymodels'})" icon="pi pi-cog"
                class="p-button-info p-button-rounded p-ml-2"
                :label="$t('config.surveymodels.buttonlabel')"/>
      </template>
    </Card>

    <Card class="p-lg-3 p-sm-12 p-ml-2">
      <template #title>
        {{ $t('config.facultysurveylink.title') }}
      </template>
      <template #content>
        <p> {{ $t('config.facultysurveylink.description') }}</p>
      </template>
      <template #footer>
        <Button @click="$router.push({name: 'facultiessurveyslinks'})" icon="pi pi-cog"
                class="p-button-info p-button-rounded p-ml-2"
                :label="$t('config.facultysurveylink.buttonlabel')"/>
      </template>
    </Card>

  </div>
</template>
<script>
export default {
  name: 'Index'
}
</script>
