import {createStore} from "vuex";
import {auth} from "./auth.module";
import {request} from "./request.module";
import {sylvia} from "./sylvia.module";
import {params} from "./params.module"

const store = createStore({
  modules: {
    auth,
    request,
    sylvia,
    params
  },
});

export default store;
