
import {Options, Vue} from 'vue-class-component'
import axios from "axios";

@Options({})
export default class Home extends Vue {
  current_role = ''

  beforeMount() {
    this.current_role = sessionStorage.getItem('currentrole') ?? ''
    //checking if there is a failed request
    let retryRequest = sessionStorage.getItem('retryRequest')
    if (retryRequest !== null) {
      let request = JSON.parse(retryRequest)
      axios.post(request.url, request.data)
        .then(() => this.$toast.add({severity: 'success', summary: this.$t('toast.request_created'), life: 3000}))
        .catch((error) => this.$toast.add({severity: 'error', summary: error, life: 3000}))
      sessionStorage.removeItem('retryRequest')
    }

    //checking if there is a redirection pending
    let routename = sessionStorage.getItem('routename')
    if (routename !== null && routename !== '') {
      this.$router.push({name: routename})
      sessionStorage.removeItem('routename')
    }
  }
}
