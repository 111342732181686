import * as htmlToImage from "html-to-image";

export class generalhelper {

  static getReceiptTimeLimits($t) {
    return [
      {name: $t('misc.less_than_5_days'), value: '<5'},
      {name: $t('misc.between_5_and_10_days'), value: '5-10'},
      {name: $t('misc.more_than_10_days'), value: '>10'}
    ]
  }

  static getStatuses($t) {
    return [
      {code: 0, label: $t('request_detail.status_0'), class: 'warning'},
      {code: 1, label: $t('request_detail.status_1'), class: 'success'},
      {code: 2, label: $t('request_detail.status_2'), class: 'success'},
      {code: 3, label: $t('request_detail.status_3'), class: 'success'},
      {code: 4, label: $t('request_detail.status_4'), class: 'success'},
    ]
  }

  static getSemesters($t) {
    return [
      {label: $t('misc.spring'), value: 'P'},
      {label: $t('misc.autumn'), value: 'A'}
    ]
  }

  static getAcadYears() {
    let current_year = new Date().getFullYear()
    let current_month = new Date().getMonth()
    if (current_month < 8) {
      this.years = [
        {label: current_year - 1 + '/' + current_year, value: current_year - 1},
      ]
    } else {
      this.years = [
        {label: current_year - 1 + '/' + current_year, value: current_year - 1},
        {label: current_year + '/' + (current_year + 1), value: current_year}
      ]
    }

    return this.years
  }

  static getHash(input) {
    input = input.normalize().replace(/’/g, "'")
    let hash = 0, i, chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash + 2147483647 + 1;
  }

  static getUniqueValuesBy(array, key) {
    return [...new Map(array.map((item) => [item[key], item])).values()]
  }

  static addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * Extract speakers list as string in order to be able to use the filters
   * @param requestList
   */
  static extract_speakers_as_list(requestList) {
    requestList.forEach(request => {
      request.speakers_list = request.speakers.map(speaker => speaker.email).join(', ')
    })
  }

  /**
   * Add closing date to the request list
   * @param requestList
   * @param locale
   */
  static add_closing_date(requestList, locale) {
    requestList.forEach(request => {
      request.steps.forEach(step => {
        if (step.type === 'close') {
          request.closing_date = this.formatDate(step.date, locale)
          request.closing_date_timestamp = step.date
        }
      })
    })
  }

  static formatDate(value, locale) {
    return new Date(value).toLocaleDateString(locale, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  /**
   * Converts charts svg to images and prints the page
   */
  static plotsToImages() {
    if(navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      return false
    }

    const elements = document.querySelectorAll(".js-plotly-plot")
    let processed = 0
    elements.forEach(function (element) {
      htmlToImage.toCanvas(element)
        .then(function (canvas) {
          const imgEl = document.createElement("img")
          imgEl.src = canvas.toDataURL("image/png")
          element.parentElement.appendChild(imgEl)
          element.style.display = "none"
          element.parentElement.removeChild(element)
          processed++
          if (processed === elements.length) {
            return true
          }
        });
    })
    return false
  }

  static saveAsExcelFile(buffer, fileName) {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }
}
