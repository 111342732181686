export class requesthelper {

  static ON_HOLD = {'status_code' : 0, 'class' : 'warning'}
  static APPROVED = {'status_code' : 1, 'class' : 'success'}
  static INVITATIONS_SENT = {'status_code' : 2, 'class' : 'success'}
  static PROCESSING_RESULTS = {'status_code' : 3, 'class' : 'success'}
  static RESULTS_AVAILABLE = {'status_code' : 4, 'class' : 'success'}
  static addSpecificNeedsToRequests(request_list, is_admin) {
    // add 'specific_needs' attribute to each request
    request_list.forEach(request => {
      request.specific_needs = []
      if (request.receipt_time_limit === '<5') {
        request.specific_needs.push('< 5 jours')
      }
      if (request.synthesis_required) {
        request.specific_needs.push('Synthèse requise')
      }
      if (is_admin && request.acceptance_rate !== undefined && request.acceptance_rate !== null && request.acceptance_rate < 60) {
        request.specific_needs.push('< 60%')
      }
    })

    return request_list
  }
}
