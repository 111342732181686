import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e5d7ece"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "p-grid p-jc-center" }
const _hoisted_3 = { class: "p-col-8" }
const _hoisted_4 = { class: "p-grid p-jc-center p-mt-3" }
const _hoisted_5 = { class: "p-lg-12" }
const _hoisted_6 = {
  key: 0,
  class: "p-lg-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("homepage.title")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("homepage.description")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.current_role !== 'editor')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/requests/add/teaching'))),
              icon: "pi pi-plus-circle",
              class: "p-button-rounded p-mr-5",
              label: _ctx.$t('homepage.new_request.button_label')
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/requests/list'))),
          icon: "pi pi-book",
          class: "p-button-info p-button-rounded",
          label: ['admin', 'editor'].includes(_ctx.current_role) ? _ctx.$t('homepage.requests.button_label') : _ctx.$t('homepage.myrequests.button_label')
        }, null, 8, ["label"])
      ]),
      (_ctx.current_role === 'admin')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({name: 'adminindex'}))),
              icon: "pi pi-user-edit",
              class: "p-button-rounded p-button-secondary p-mr-5",
              label: _ctx.$t('homepage.admin.button_label')
            }, null, 8, ["label"]),
            _createVNode(_component_Button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({name: 'configindex'}))),
              icon: "pi pi-cog",
              class: "p-button-rounded p-button-secondary",
              label: _ctx.$t('homepage.config.button_label')
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}