import router from "@/router";

export const auth = {
  namespaced: true,
  state: {
    loggedIn: false,
    isadmin: false,
    isteacher: false,
    roles: []
  },
  actions: {
    login({commit}) {
      const urlParams = new URLSearchParams(window.location.search);
      const jwt = urlParams.get('token')
      if (jwt) {
        commit('login', jwt)
      } else {
        router.push('/')
      }
    },
    logout({commit}) {
      window.location.href = process.env.VUE_APP_LOGOUT_URL
      commit('logout')
    },
  },
  mutations: {
    login(state, token) {
      state.loggedIn = true
      sessionStorage.removeItem('token')
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('userdata', atob(token.split('.')[1]))
      state.roles = JSON.parse(sessionStorage.userdata).roles
      sessionStorage.setItem('currentrole', JSON.parse(sessionStorage.userdata).roles[0])
    },
    logout(state) {
      state.loggedIn = false
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userdata')
      sessionStorage.removeItem('currentrole')
    },
  },
  getters: {
    token: () => {
      return sessionStorage.getItem('token')
    },
    userdata: () => {
      return JSON.parse(sessionStorage.getItem('userdata'))
    },
    roles: (state) => {
      return sessionStorage.userdata ? JSON.parse(sessionStorage.userdata).roles : state.roles
    }
  }
};
